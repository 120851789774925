import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Trans } from 'gatsby-plugin-react-i18next'

const CmpcPage = (props) => {
  return (
    <Layout location={props.location}>
      <Seo title='Sobre CMPC' />
      <div>
        <div className='grid h-screen cmpc-hero'>
          <StaticImage
            style={{
              gridArea: '1/1',
            }}
            layout='fullWidth'
            // You can optionally force an aspect ratio for the generated image
            // aspectRatio={3 / 1}
            // className='absolute inset-0 z-0 object-cover w-full'
            src='../images/bg-cmpc.jpg'
            placeholder='none'
            alt=''
            loading='eager'
          />
          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: '1/1',
              position: 'relative',
              // This centers the other elements inside the hero component
              // placeItems: 'center',
              // display: 'grid',
            }}
            className='flex flex-col items-center justify-center max-w-xs mx-auto text-white lg:text-xl lg:container'
          >
            <div className='w-48 text-center lg:max-w-xs lg:w-full'>
              <StaticImage
                className='w-full mx-auto mb-4'
                layout='fixed'
                placeholder='none'
                width={263}
                src='../images/logo-cmpc-color.png'
                alt='CMPC'
              />
              <Trans>Imaginando el futuro de nuestras fibras</Trans>
            </div>
          </div>
        </div>
        <section className='w-screen py-24 overflow-x-hidden bg-beige'>
          <div className='container px-4 mx-auto'>
            <div className='max-w-3xl '>
              <h2 className='mb-12 text-2xl font-extrabold lg:text-4xl'>
                <Trans>
                  CMPC es una compañía global, con 100 años de historia que
                  entrega soluciones sostenibles a sus clientes y consumidores.
                </Trans>
              </h2>

              <p className='mb-4'>
                <Trans>
                  CMPC desarrolla productos innovadores para contribuir en la
                  transición hacia una bioeconomía circular, que genere valor y
                  entregue alternativas concretas a consumidores y clientes. Su
                  quehacer es la producción y comercialización de madera,
                  celulosa, productos de embalaje, productos sanitarios, de
                  fuera del hogar y de papel tissue. Estos satisfacen
                  necesidades de personas, destacando su calidad,
                  competitividad, y elaboración a base de recursos renovables.
                </Trans>
              </p>

              <p>
                <Trans>
                  En su operar hace parte de alianzas internacionales aportando
                  a la sostenibilidad del planeta, a la dinamización de las
                  economías locales, impactando de manera positiva a sus
                  comunidades con quienes comparte territorios.
                </Trans>
              </p>
            </div>

            <div className='relative grid mb-16 lg:grid-flow-row-dense lg:grid-cols-4 balls'>
              <div className='lg:col-span-1 lg:col-start-4 '>
                <div
                  className='relative flex flex-col items-center justify-center font-extrabold rounded-full bg-green ball-0'
                  // style={{
                  //   width: 600,
                  //   height: 600,
                  //   marginTop: -240,
                  //   marginLeft: -120,
                  // }}
                >
                  <span
                    className='text-black ball-0__txt-big'
                    // style={{ fontSize: '274px', lineHeight: 0.9 }}
                  >
                    100
                  </span>
                  <span className='text-2xl text-center text-white lg:text-2xl ball__txt-mid'>
                    <Trans i18nKey='dataYears'>
                      años de <br /> historia
                    </Trans>
                  </span>
                </div>

                <div
                  className='flex flex-col items-center justify-center font-extrabold bg-black rounded-full ball-1'
                  // style={{
                  //   width: 600,
                  //   height: 600,
                  //   marginTop: -240,
                  //   marginLeft: -120,
                  // }}
                >
                  <span
                    className=' text-green ball-1__txt-big'
                    // style={{ fontSize: '274px', lineHeight: 0.9 }}
                  >
                    11
                  </span>
                  <Trans i18nKey='dataCountries'>
                    <span className='text-5xl text-green lg:text-7xl ball__txt-mid'>
                      países
                    </span>
                    <span className='mb-16 text-2xl lg:text-4xl text-beige ball__txt-sm'>
                      con operaciones
                    </span>
                  </Trans>
                </div>
              </div>

              <div className='flex flex-col items-center justify-center font-extrabold bg-white rounded-full lg:col-span-1 lg:col-start-3 ball-2 text-green'>
                <span className='ball-2__txt-big'>46</span>
                <Trans i18nKey='dataPlants'>
                  <span className='text-5xl lg:text-7xl'>plantas</span>
                  <span className='text-2xl text-black lg:text-4xl '>
                    totales
                  </span>
                </Trans>
              </div>

              <div className='flex flex-col items-center justify-center px-12 font-extrabold text-black rounded-full lg:col-span-1 lg:col-start-2 ball-3 bg-green'>
                <span className='mb-4 text-center ball-3__txt-big'>90,3%</span>
                <span className='max-w-xs text-sm text-center text-white lg:px-4 lg:text-2xl'>
                  <Trans>
                    del patrimonio forestal propio se encuentra certificado con
                    manejo de forestal sostenible
                  </Trans>
                </span>
              </div>

              <div className='relative flex flex-col items-center justify-center px-12 font-extrabold bg-black rounded-full lg:col-span-1 lg:col-start-1 ball-4 text-green'>
                <span className='mb-4 text-center ball-4__txt-big'>79%</span>

                <span className='max-w-sm text-xs text-center lg:text-2xl text-beige'>
                  <Trans>
                    del total de la energía eléctrica autogenerada es a partir
                    de Energías Renovables No Convencionales, siendo la biomasa
                    la principal fuente
                  </Trans>
                </span>
              </div>

              <div className='flex flex-col items-center justify-center px-12 font-extrabold text-black bg-white rounded-full lg:row-start-2 lg:col-span-1 lg:col-start-2 ball-5'>
                <span className='mb-4 text-center ball-5__txt-big'>
                  <Trans>385.726</Trans>
                </span>
                <span className='max-w-xs text-sm text-center lg:px-4 text-orange lg:text-xl'>
                  <Trans>hectáreas de conservación y restauración</Trans>
                </span>
              </div>
            </div>
          </div>

          <div className='mb-24 border-t border-b -mt-28'>
            <aside className='grid items-center mx-auto text-center divide-x-0 divide-y divide-black lg:divide-x lg:divide-y-0 lg:px-0 lg:text-left lg:container lg:grid-cols-3'>
              <div className='flex items-center justify-center w-full h-full py-20 text-center'>
                <p className=''>
                  <span className='block text-3xl font-extrabold'>
                    <Trans>1.189.680</Trans> ha
                  </span>{' '}
                  <Trans>de patrimonio forestal</Trans>
                </p>
              </div>

              {/* <div className='flex items-center justify-center w-full h-full py-20 text-center'>
                <p className=''>
                  <span className='block text-3xl font-extrabold'>
                    325.995 ha
                  </span>{' '}
                  de protección, conservación y restauración
                </p>
              </div> */}

              <div className='flex items-center justify-center w-full h-full py-20 text-center'>
                <p className=''>
                  <span className='block text-3xl font-extrabold'>
                    <Trans>6.815</Trans>
                  </span>{' '}
                  <Trans>proveedores Mipymes</Trans>
                </p>
              </div>

              <div className='flex items-center justify-center w-full h-full py-20 text-center'>
                <p className=''>
                  <span className='block text-3xl font-extrabold'>
                    <Trans>19.641</Trans>
                  </span>{' '}
                  <Trans>colaboradores directos</Trans>
                </p>
              </div>
            </aside>
          </div>

          <a
            className='flex items-center justify-center w-full px-6 py-3 mx-auto text-2xl font-semibold uppercase bg-black border-none rounded-full text-green lg:max-w-xs button-noticias'
            href='https://cmpc.com/'
          >
            <Trans>Ir a cmpc.com</Trans>
          </a>
        </section>
      </div>
    </Layout>
  )
}

export default CmpcPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "cmpc"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`